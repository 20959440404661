<template>
  <transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="opacity-0 -mt-96"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0 -mt-96"
  >
    <div
      class="fixed z-30 inset-0 overflow-y-auto"
      role="dialog"
      v-if="show"
      @click="tryClose"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >
        <div
          open
          v-if="show"
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-3/4"
        >
          <div
            class="bg-white px-4 pt-5 my-2 pb-4 sm:p-6 sm:pb-4 flex justify-center"
          >
            <div class="text-center">
              <h2 class="m-2">{{ title }}</h2>
              <div class="m-2">
                <slot></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    errorIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {},
};
</script>

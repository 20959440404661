<template>
  <form @submit.prevent="submitModal">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-20
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="
          hideScroll
          flex flex-col
          min-w-lg
          max-w-6xl
          mx-auto
          rounded-lg
          border border-lighGrey
          z-20
          overflow-scroll
          max-h-5/6
          overflow-x-hidden
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Edit Staff Hire
          </h2>

          <!-- Job title / Contract Type -->
          <div class="sm:flex my-1 md:ml-10">
            <!-- Job Title -->
            <div class="w-full">
              <label for="jobTitle" class="flex pb-1">Job Title:</label>
              <div class="w-9/12 md:w-11/12 relative">
                <v-select
                  :options="getRoles"
                  :filterable="true"
                  :placeholder="'Please select...'"
                  @open="onOpen"
                  @close="onClose"
                  v-model="hiringRequest.job_title"
                  :class="
                    v$.hiringRequest.job_title.$error === true
                      ? 'style-chooser-error'
                      : 'style-chooser'
                  "
                >
                  <template #list-footer>
                    <li v-show="rolesHasNextPage" ref="loads" class="loader">
                      Loading more options...
                    </li>
                  </template>
                </v-select>
              </div>
            </div>
            <!-- Contract Type -->
            <div class="w-full">
              <label for="contractType" class="w-3/12">Contract Type:</label>
              <select
                v-model="hiringRequest.contract_type"
                name="contractType"
                class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                :class="
                  v$.hiringRequest.contract_type.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              >
                <option :value="undefined" disabled selected>
                  Please Select...
                </option>
                <option value="Permanent">Permanent</option>
                <option value="Casual">Casual</option>
                <option value="Fixed-term">Fixed Term</option>
                <option value="Zero-hour">Zero-Hour</option>
              </select>
            </div>
          </div>
          <!-- Department / Reporting To -->
          <div class="sm:flex my-1 md:ml-10">
            <!-- Department -->
            <div class="w-full">
              <label for="department" class="w-3/12">Department:</label>
              <select
                name="department"
                v-model="hiringRequest.department"
                class="rounded-lg border bg-white w-full md:w-11/12 h-10 pl-2"
                :class="
                  v$.hiringRequest.department.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              >
                <option :value="undefined" selected disabled>
                  Please Select...
                </option>
                <option
                  v-for="department in getPracticeDepartments"
                  :key="department.id"
                  :value="department.id"
                >
                  {{ department.name }}
                </option>
              </select>
            </div>
            <!-- Reporting To -->
            <div class="w-full">
              <label for="reportingTo" class="w-3/12">Reporting To:</label>
              <select
                v-model="hiringRequest.reporting_to"
                name="reportingTo"
                class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                :class="
                  v$.hiringRequest.reporting_to.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              >
                <option :value="undefined" selected disabled>
                  Please Select...
                </option>
                <option
                  v-for="department in getPracticeDepartments"
                  :key="department.id"
                  :value="department.department_head.id"
                >
                  {{
                    `${department.department_head.profile.first_name} ${department.department_head.profile.last_name} (${department.department_head.email})`
                  }}
                </option>
              </select>
            </div>
          </div>
          <!-- Starting Date / Starting Salary -->
          <div class="sm:flex my-1 md:ml-10">
            <!-- Starting Date -->
            <div class="w-full md:w-1/2">
              <label for="startDate" class="w-3/12">Starting Date</label>
              <input
                v-model="hiringRequest.start_date"
                name="startDate"
                type="date"
                class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                :class="
                  v$.hiringRequest.start_date.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              />
            </div>
            <!-- Starting Salary -->
            <div class="w-full md:w-1/2">
              <label for="startingSalary" class="w-3/12">Starting Salary</label>
              <select
                v-model="hiringRequest.starting_salary"
                name="startingSalary"
                class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                :class="
                  v$.hiringRequest.starting_salary.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              >
                <option :value="undefined" selected disabled>
                  Please Select...
                </option>
                <option value="Band 1">Band 1</option>
                <option value="Band 2">Band 2</option>
                <option value="Band 3">Band 3</option>
                <option value="Band 4">Band 4</option>
                <option value="Band 5">Band 5</option>
              </select>
            </div>
          </div>
          <!-- Job / Person Spec -->
          <div class="sm:flex my-1 md:ml-10">
            <!-- Job Spec -->
            <div class="w-full md:w-1/2">
              <label for="jobSpecification" class="w-full"
                >Job Specification:</label
              >
              <select
                v-if="!jobSpecIsLoading"
                name="jobSpecification"
                v-model="hiringRequest.job_specification"
                @change="fetchSingleJobSpec"
                class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                :class="
                  v$.hiringRequest.job_specification.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              >
                <option :value="undefined" selected disabled>
                  Please Select...
                </option>
                <option
                  v-for="jobSpec in getJobSpecifications"
                  :key="jobSpec.id"
                  :value="jobSpec.id"
                >
                  {{ jobSpec.title }}
                </option>
              </select>
            </div>
            <!-- Starting Salary -->
            <div class="w-full md:w-1/2">
              <label for="personSpecification" class="w-full"
                >Person Specification:</label
              >
              <select
                v-if="!personSpecIsLoading"
                name="personSpecification"
                v-model="hiringRequest.person_specification"
                @change="fetchSinglePersonSpec"
                class="w-9/12 rounded-lg border bg-white md:w-11/12 h-10 pl-2"
                :class="
                  v$.hiringRequest.person_specification.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              >
                <option :value="undefined" selected disabled>
                  Please Select...
                </option>
                <option
                  v-for="personSpec in getPersonSpecifications"
                  :key="personSpec.id"
                  :value="personSpec.id"
                >
                  {{ personSpec.name }}
                </option>
              </select>
            </div>
          </div>
          <!-- Rota Information -->
          <div class="sm:flex my-1 md:ml-10">
            <!-- Rota Information -->
            <div class="w-11.5/12 pr-2">
              <!-- Rota Information -->
              <div class="flex w-full mb-2">
                <rota-card
                  :data="getHiringRequestData.work_patterns[0].work_timings"
                  bgColour="bg-white"
                  class="w-full"
                  @selectedPattern="updateSelectedPattern"
                  :showSelect="true"
                  :border="
                    v$.hiringRequest.rota_information.$error === true
                      ? 'border-red'
                      : 'border-darkGrey'
                  "
                ></rota-card>
              </div>
            </div>
          </div>
        </div>

        <!-- Cancel / Post Modal -->
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <!-- Cancel Modal -->
          <p
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
            @click="closeModal"
          >
            Cancel
          </p>
          <!-- Post Modal -->
          <base-button :animateButton="animateButton"> Submit </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import rotaCard from "@/components/ui/baseComponents/rotaCard.vue";
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    getHiringRequestData: {
      type: Object,
      required: true,
    },
  },
  emits: ["close-modal", "submit-modal"],
  components: { rotaCard },
  mounted() {
    // this.hiringRequest = this.getHiringRequestData;
    this.hiringRequest.hiring_request = this.getHiringRequestData.id;
    this.hiringRequest.job_title = this.getHiringRequestData.job_title;
    this.hiringRequest.contract_type = this.getHiringRequestData.contract_type;
    this.hiringRequest.department = this.getHiringRequestData.department_id;
    // this.hiringRequest.reporting_to = parseInt(
    //   this.getHiringRequestData.reporting_to
    // );
    this.hiringRequest.start_date = this.getHiringRequestData.start_date;
    this.hiringRequest.starting_salary =
      this.getHiringRequestData.starting_salary;
    this.hiringRequest.reason_for_recruitment =
      this.getHiringRequestData.reason_for_recruitment;
    // this.hiringRequest.job_specification =
    //   this.getHiringRequestData.job_specification_id;
    // this.hiringRequest.person_specification =
    //   this.getHiringRequestData.person_specification_id;
  },
  created() {
    this.loadRoles(this.rolesPage);
    this.fetchPracticeDepartaments();
    this.fetchJobSpecifications();
    this.fetchPersonSpecifications();
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  data() {
    return {
      v$: useVuelidate(),
      rolesPage: 1,
      personSpecIsLoading: false,
      singlePersonSpecIsLoading: false,
      jobSpecIsLoading: false,
      singleJobSpecIsLoading: false,
      observer: null,
      roleData: [],
      hiringRequest: {
        hiring_request: null,
        job_title: null,
        contract_type: null,
        department: null,
        reporting_to: null,
        start_date: null,
        starting_salary: null,
        reason_for_recruitment: null,
        job_specification: null,
        person_specification: null,
        rota_information: null,
        // name: null,
        // start_time: null,
        // end_time: null,
        // break_time: null,
        // repeat_days: null,
        singlePersonSpec: null,
        singleJobSpec: null,
      },
    };
  },
  validations() {
    return {
      hiringRequest: {
        job_title: { required },
        contract_type: { required },
        department: { required },
        reporting_to: { required },
        start_date: { required },
        starting_salary: { required },
        reason_for_recruitment: { required },
        job_specification: { required },
        person_specification: { required },
        rota_information: { required },
      },
    };
  },
  methods: {
    submitModal() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-modal", this.hiringRequest);
      }
    },
    updateSelectedPattern(data) {
      this.hiringRequest.rota_information = data;
    },
    async onOpen() {
      if (this.rolesHasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.loads);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        this.rolesPage++;
        this.loadRoles(this.rolesPage);
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async loadRoles(rolesPage) {
      try {
        await this.$store.dispatch("admin/loadRoles", rolesPage);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchPracticeDepartaments() {
      try {
        await this.$store.dispatch("MAnewHire/fetchPracticeDepartaments", {
          practice: 1,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchJobSpecifications() {
      try {
        (this.jobSpecIsLoading = true),
          await this.$store.dispatch("MAnewHire/fetchJobSpecifications", {
            practice: localStorage.getItem("practice"),
          });
        this.jobSpecIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchPersonSpecifications() {
      try {
        (this.personSpecIsLoading = true),
          await this.$store.dispatch("MAnewHire/fetchPersonSpecifications", {
            practice: localStorage.getItem("practice"),
          });
        this.personSpecIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchSinglePersonSpec() {
      try {
        (this.singlePersonSpecIsLoading = true),
          await this.$store.dispatch("MAnewHire/fetchSinglePersonSpec", {
            person_specification: this.hiringRequest.person_specification,
          });
        this.singlePersonSpecIsLoading = false;
        this.hiringRequest.singlePersonSpec = this.getSinglePersonSpecification;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchSingleJobSpec() {
      try {
        (this.singleJobSpecIsLoading = true),
          await this.$store.dispatch("MAnewHire/fetchSingleJobSpec", {
            job_specification: this.hiringRequest.job_specification,
          });
        this.singleJobSpecIsLoading = false;
        this.hiringRequest.singleJobSpec = this.getSingleJobSpecification;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    closeModal() {
      this.$emit("close-modal");
    },
  },
  computed: {
    getRoles() {
      return this.$store.getters["admin/getRoles"];
    },
    rolesHasNextPage() {
      // Determine how many pages
      const data = this.$store.getters["admin/getRoles"];
      const totalPages = Math.ceil(data.total / 10);
      if (this.rolesPage <= totalPages) {
        return true;
      }
      return false;
    },
    getPracticeDepartments() {
      return this.$store.getters["MAnewHire/getPracticeDepartments"];
    },
    getJobSpecifications() {
      return this.$store.getters["MAnewHire/getJobSpecifications"];
    },
    getSingleJobSpecification() {
      return this.$store.getters["MAnewHire/getSingleJobSpecification"];
    },
    getPersonSpecifications() {
      return this.$store.getters["MAnewHire/getPersonSpecifications"];
    },
    getSinglePersonSpecification() {
      return this.$store.getters["MAnewHire/getSinglePersonSpecification"];
    },
  },
};
</script>

<style>
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: 1px solid #425563;
  height: 10rem;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}
.style-chooser .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}
.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: black;
}
.style-chooser-error .vs__dropdown-toggle,
.style-chooser-error .vs__dropdown-menu {
  border: 1px solid red;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
  height: 10rem;
}
.style-chooser-error .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}
.style-chooser-error .vs__clear,
.style-chooser-error .vs__open-indicator {
  fill: black;
}
</style>
